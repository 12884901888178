import { useState, useEffect, ChangeEvent } from "react";
import { FloatingLabelInput } from "../../components/input/FloatingLabelInput";
import HButton from "../../components/common/hbutton/HButton";
import { mdiFaceAgent, mdiAlertCircleOutline, mdiClose } from "@mdi/js";
import { LanguageSelector } from "../../pages/landing/components/header";
import { ICustomerStore } from "../../interfaces";
import { useCustomerStore } from "../../store/customerStore";
import { emailRegex } from "../../utils/ExpRegex";
import { HeaderLogin } from "./components/HeaderLogin";
import { VerificationCode } from "./components/VerificationCode";
import Slider from "../../components/slider/Slider";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { Trans } from "react-i18next";
import { LinkText } from "../../components/linkText/LinkText";
import { t } from "i18next";

import { sendEmailVerification } from "../../services";
import ProvidersAccessButtons from "./components/ProvidersAccessButtons";

const buttonStylesDefault = {
  standard:
    "bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold text-white hover:bg-gradient-to-l from-magenta to-magenta-900",
  default: "bg-neutral-200 text-neutral-400 ",
};

export const Login = () => {
  const {
    language,
    isOpenMenuLanguage,
    toggleMenuLanguage,
    emailLocalStorage,
    setEmailLocalStorage,
  } = useCustomerStore((store: ICustomerStore) => store);

  const [email, setEmail] = useState<string>(emailLocalStorage);
  const [isChecked, setIsChecked] = useState<boolean>(
    emailLocalStorage.length > 0
  );
  const [isVerification, setIsVerification] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const navigate = useNavigate();

  const handleChange = (value: string) => {
    value.length <= 150 && setEmail(value);
  };
  const hideVerificationCode = () => {
    setIsVerification(false);
    setEmail("");
  };

  const handleNavigateToHelp = () => navigate("/helpCenter");

  const onChecked = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.currentTarget.checked);
    e.currentTarget.checked
      ? setEmailLocalStorage(email ?? "")
      : setEmailLocalStorage("");
  };

  const handleSendVerification = () => {
    if (!emailRegex.test(email)) {
      setIsValid(false);
      return;
    }

    sendEmailVerification({ email, language });
    setIsVerification(true);
    setIsValid(true);
  };

  const closeError = () => setError("");

  useEffect(
    function saveEmailOnLocalStorage() {
      if (isChecked && email.length > 5) {
        setEmailLocalStorage(email);
      }
    },
    [email, isChecked, setEmailLocalStorage]
  );

  return (
    <div className="relative">
      {error && (
        <div
          className="absolute z-10 bg-semantic-error-100 rounded-xl
         grid grid-cols-[auto,1fr,auto] justify-center items-start md:items-center
         p-6 gap-1 mt-1 md:mt-16
        max-w-[808px] min-w-[380px]
          left-1/2 transform -translate-x-1/2
        "
        >
          <Icon
            path={mdiAlertCircleOutline}
            className="text-semantic-error-600 w-6 p-[2px]"
          />
          <p className="text-sm font-normal text-semantic-error-600 text-pretty">
            {error}
          </p>
          <button onClick={closeError}>
            <Icon path={mdiClose} className="text-semantic-error-600 w-6" />
          </button>
        </div>
      )}
      {!isVerification ? (
        <div className="bg-white h-full md:h-screen w-full py-12 md:px-16 px-6 flex justify-center items-center">
          <div className="flex flex-row h-full gap-8 justify-between items-center lg:w-[1224px] ">
            <div className="flex flex-col w-full relative">
              <HeaderLogin />
              <div className="mt-6 bg-red-200" />

              <div className="flex flex-col">
                <div className="flex flex-col md:items-start items-center gap-6">
                  <p className="text-black text-base font-bold md:text-start text-center ">
                    <Trans i18nKey="global.customerV2.new-login.title" />
                  </p>

                  <p className="text-neutral-charcoal md:text-start text-center text-sm">
                    {t("global.customerV2.new-login.description")}
                  </p>
                </div>

                <div className="flex flex-col md:gap-6 gap-5 mt-3">
                  <div className="flex flex-col gap-2">
                    <FloatingLabelInput
                      label={t("global.login.label")}
                      name="username"
                      type="text"
                      value={email}
                      error={!isValid}
                      errorMessage={t("global.login.errors.validFormatEmail")}
                      onChange={handleChange}
                    />

                    <div className="flex flex-row gap-2 items-center">
                      <input
                        className="border-neutral-400 border rounded-[4px] appearance-none w-4 h-4 checked:focus:bg-slate-200 checked:bg-check bg-no-repeat bg-contain"
                        type={"checkbox"}
                        checked={isChecked}
                        onChange={onChecked}
                      />
                      <p className="text-neutral-charcoal text-sm font-bold">
                        {t("global.login.checkRemember")}
                      </p>
                    </div>
                  </div>

                  <HButton
                    type="button"
                    name="button login"
                    className={`h-btn w-full h-12 px-5 py-3 rounded-2xl font-medium text-base flex items-center justify-center ${
                      email.length > 5
                        ? buttonStylesDefault.standard
                        : buttonStylesDefault.default
                    }`}
                    disabled={email.length < 5}
                    text={t("global.login.btnLogin")}
                    onClick={handleSendVerification}
                  />
                </div>

                <div className="flex flex-row items-center gap-1 justify-center md:my-8 my-6">
                  <div className="bg-neutral-300 rounded-[1px] h-0 border-b w-full" />
                  <p className="text-center w-full text-sm font-bold whitespace-nowrap">
                    {t("global.login.withContinue")}
                  </p>
                  <div className="bg-neutral-300 rounded-[1px] h-0 border-b w-full" />
                </div>
              </div>

              <div className="flex flex-col h-full justify-between">
                <div className="flex flex-col gap-6">
                  <ProvidersAccessButtons setError={setError} />
                  <HButton
                    type="button"
                    name="button need help"
                    className="flex gap-2 flex-row justify-center items-center h-12 border-none text-neutral-charcoal text-base font-medium"
                    icon={mdiFaceAgent}
                    text={t("global.login.btnNeedHelp")}
                    onClick={handleNavigateToHelp}
                  />
                </div>

                <p className="text-neutral-500 text-xs font-normal text-center mt-6">
                  <Trans
                    i18nKey="global.login.termsAndPolitics"
                    components={{
                      p1: (
                        <LinkText
                          to={t("global.login.linkTerms")}
                          className="font-bold underline"
                        />
                      ),
                      p2: (
                        <LinkText
                          to={t("global.login.linkPolitics")}
                          className="font-bold underline"
                        />
                      ),
                    }}
                  />
                </p>
              </div>
            </div>
            <div className="w-full items-center lg:flex justify-center hidden">
              <Slider />
            </div>
          </div>
        </div>
      ) : (
        <VerificationCode
          hideVerificationCode={hideVerificationCode}
          email={email}
          setError={setError}
        />
      )}
      {isOpenMenuLanguage && (
        <LanguageSelector
          handleChangeVisible={() => toggleMenuLanguage(false)}
        />
      )}
    </div>
  );
};
