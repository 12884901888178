import { Trans, useTranslation } from "react-i18next";
import discountIcon from "../../../../assets/landing/discount/discount.svg";
import HButton from "../../../../components/common/hbutton/HButton";
import { ArrowEast } from "../../../../components/svg";

export function Discount() {
  const { t } = useTranslation("translation", {
    keyPrefix: "global.customerV2.travelClub.landing",
  });
  return (
    <section className="flex flex-col py-10 px-4 items-center">
      <img
        src={discountIcon}
        alt="discount"
        className="mb-4 sm:mb-3"
        draggable={false}
      />

      <h2 className="font-medium text-[22px] leading-7 sm:text-[28px] sm:leading-[34px] mb-4 sm:mb-3">
        <Trans
          i18nKey="global.customerV2.travelClub.landing.10%.title"
          components={{
            span: <span className="text-2xl font-medium text-primary" />,
          }}
        />
      </h2>

      <p className="mb-5 text-center text-base leading-[22px] max-w-[592px] sm:text-lg sm:leading-[34px]">
        {t("10%.description")}
      </p>

      <input
        className="border rounded-xl w-full max-w-[696px] px-4 py-3 mb-5 "
        placeholder={t("10%.field")}
      />

      <HButton
        type="button"
        className="text-white rounded-xl w-full sm:w-auto bg-gradient/holafly hover:bg-gradient/holafly-hover px-5 py-3 mb-5 flex flex-row-reverse"
        text={t("10%.button")}
        name="discount_button"
        icon={<ArrowEast fill="#fff" />}
      />

      <span className="text-center text-sm">{t("10%.disclaimer")}</span>
    </section>
  );
}
