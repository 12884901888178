import React from "react";
import { ICustomerStore, IStepProps } from "../../../../interfaces";
import { Trans, useTranslation } from "react-i18next";
import { useCustomerStore } from "../../../../store/customerStore";

export const Step = ({
  number,
  text,
  imageSrc,
  imageAlt,
}: IStepProps): JSX.Element => {
  const { t } = useTranslation();
  const { language } = useCustomerStore((store: ICustomerStore) => store);

  const linkUrl =
    number === "01"
      ? t("global.link.shop")
      : `${process.env.REACT_APP_URL}/login?language=${language}`;

  const linkClass = `font-medium ${number !== "03" ? "underline" : ""}`;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-6 items-center justify-items-center">
      <img
        draggable={false}
        width={208}
        height={180}
        src={imageSrc}
        alt={imageAlt}
      />
      <div className="flex flex-col gap-3 items-center md:items-start">
        <h5 className="text-yellow-600 text-xl font-medium">{number}</h5>
        <p
          className={
            "text-neutral-charcoal text-base font-normal text-center md:text-start w-full md:w-[348px]"
          }
        >
          <Trans
            i18nKey={text}
            components={{
              strong: <a href={linkUrl} className={linkClass} />,
            }}
          />
        </p>
      </div>
    </div>
  );
};
