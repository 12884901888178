import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LayoutPageWithMapBackground } from "../../components/layouts/LayoutPageWithMapBackground";
import { Divider } from "../../components/common/divider/Divider";
import {
  ArrowEast,
  ArrowWest,
  UserReferralIcon,
  Monetization,
  Cashback,
  HolaCoinIcon,
  ArrowChevronDown,
} from "../../components/svg";
import { useCustomerStore } from "../../store/customerStore";
import { CuponHistoryTravelClub, ICustomerStore } from "../../interfaces/store";
import {
  capitalizeFirstLetter,
  getMonthName,
  isNumberPostiverOrNegative,
} from "../../utils";
import HButton from "../../components/common/hbutton/HButton";
import { useNavigate } from "react-router-dom";
import { INITIAL_MONTHS } from "../../constants";
import {
  EmptyHistoryTravelClub,
  LoadingSkeleton,
} from "./components/historyTravelClub";

const HistoryTravelClubPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { referralUser, clearReferralUser, fetchReferralGetUser } =
    useCustomerStore((store: ICustomerStore) => store);
  const [visibleMonths, setVisibleMonths] = useState(INITIAL_MONTHS);

  const handleShowMoreMonths = () => {
    setVisibleMonths((prevCount) => prevCount + INITIAL_MONTHS);
  };

  const handleShowLessMonths = () => {
    setVisibleMonths(INITIAL_MONTHS);
  };

  const SHOW_VALIDATION_ICON_HISTORY = {
    "redeemed coupon": <Cashback />,
    Cashback: <Cashback />,
    "Refer a friend": <UserReferralIcon />,
    "Manual Adjustment": <Monetization />,
  };

  const handleBack = () => {
    navigate("/referralBoard");
    clearReferralUser();
    fetchReferralGetUser("RedeemedCoupon", false);
  };

  return (
    <LayoutPageWithMapBackground
      title={t("global.customerV2.travelClub.history.title")}
      handleBack={handleBack}
      btnLabel={t("global.customerV2.buttons.backToHTC")}
    >
      {!referralUser ? (
        <LoadingSkeleton />
      ) : referralUser?.cupon_history.length === 0 ? (
        <EmptyHistoryTravelClub />
      ) : (
        <div className="lg:px-[220px] flex flex-col gap-10 transition-opacity ">
          {referralUser?.cupon_history
            .slice(0, visibleMonths)
            .map((item, index) => {
              const { data, year, month } = item as CuponHistoryTravelClub;
              return (
                <div
                  key={`${year}-${month}-${index}`}
                  className="flex flex-col gap-5 transition-opacity duration-300"
                >
                  <h2 className="text-xl font-medium text-neutral-charcoal">
                    {t(
                      `global.customerV2.${getMonthName(
                        year,
                        month
                      ).toLocaleLowerCase()}`
                    )}
                  </h2>

                  <Divider className="bg-neutral-300 h-[1px] w-full" />

                  {data?.map((history, idx) => {
                    return (
                      <div key={idx} className="flex flex-col gap-5">
                        <div className="flex flex-row gap-5 items-center justify-between">
                          <div className="flex flex-row gap-2 items-center">
                            {SHOW_VALIDATION_ICON_HISTORY[history.type]}
                            <p className="text-base font-normal text-neutral-charcoal">
                              {capitalizeFirstLetter(history.type)}
                            </p>
                          </div>

                          <div className="flex flex-row gap-2 items-center">
                            {isNumberPostiverOrNegative.test(
                              String(history.cash)
                            ) ? (
                              <ArrowEast fill="#E6485C" />
                            ) : (
                              <ArrowWest fill="#32A45E" />
                            )}

                            <p className="text-base font-bold text-neutral-charcoal">
                              {isNumberPostiverOrNegative.test(
                                String(history.cash)
                              )
                                ? `${history.cash}`
                                : `+${history.cash}`}
                            </p>
                            <HolaCoinIcon className="w-[24px] h-[24px]" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}

          <div className="flex flex-col gap-3">
            {referralUser?.cupon_history.length > 2 &&
              visibleMonths < referralUser?.cupon_history.length && (
                <HButton
                  type="button"
                  name="show more months"
                  text={t("global.customerV2.cta.loadMore")}
                  onClick={handleShowMoreMonths}
                  icon={<ArrowChevronDown />}
                  className="flex flex-row-reverse justify-center items-center hover:bg-neutral-300 text-neutral-charcoal text-base font-medium cursor-pointer py-3 px-5 border border-neutral-300 rounded-xl"
                />
              )}
            {visibleMonths >= referralUser?.cupon_history.length && (
              <HButton
                type="button"
                name="show less months"
                text={t("global.customerV2.cta.seeLess")}
                onClick={handleShowLessMonths}
                icon={<ArrowChevronDown className="rotate-180" />}
                className="flex flex-row-reverse justify-center items-center hover:bg-neutral-300 text-neutral-charcoal text-base font-medium cursor-pointer py-3 px-5 border border-neutral-300 rounded-xl"
              />
            )}
          </div>
        </div>
      )}
    </LayoutPageWithMapBackground>
  );
};

export default HistoryTravelClubPage;
